@import "~@openfonts/jomhuria_latin-ext/index.css";
@import "@fontsource/agbalumo";

/* Contenedor principal */
.home-container{
    margin: 0;
}

/* Estilo para el banner */
.banner{
    background-color: var(--bg-amarillo-3);
    color: var(--bg-blanco);
    position: relative;
    font-family: 'Jomhuria', sans-serif;
    height: 278px;
    padding: 0 40px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 0.5;
}

/* Contenido de texto dentro del banner */
.text-content{
    align-self: flex-start;
    padding-top: 50px;
}

.text-content h1{
    font-size: 128px;
    margin: 0;
}

.text-content h2{
    font-size: 96px;    
}

/* Botones dentro del banner */
.btn-products-home{
    background-color: var(--bg-rojo);
    border-radius: 20px;
    font-size: 20px;
    font-family: 'Agbalumo', sans-serif;
    width: 180px;
    height: 47px;
    margin-top: 10px;
}

button:hover {
    background-color: #a83a1c;
}

/* Imagen dentro del banner */
.img-canasta{
    padding-top: 60px;
}

/* Estilo del título dentro del header */
.title2-header{
    background-color: var(--bg-amarillo-1);
    font-family: 'Jomolhari', sans-serif;
    font-size: 24px;
    padding: 5px 92px;
}

/* Sección de productos destacados */
.featured-products{
    background-color: var(--bg-amarillo-1);
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-around;
}

.featured-products div{
    display: flex;
    background-color: var(--bg-amarillo-4);
    border-radius: 20px;
    width: 277px;
    height: 110px;
    margin: 8px; 
}

.featured-products div div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: var(--family-body);
}

/* Para dispositivos más pequeños */
@media screen and (max-width: 768px) {
    .banner {        
        text-align: center; 
        height: auto; 
        justify-content: center; 
        align-items: center; 
        margin: auto;        
        padding: 70px;
    }

    .img-canasta{
        display: none;
    }

    .title2-header{
        text-align: center;
    }

    .text-content h1 {
        font-size: 120px; 
    }

    .text-content h2 {
        font-size: 80px; 
        margin-bottom: 40px;
    }

    .btn-products-home {
        width: 200px;
        height: 60px;
        font-size: 25px;
    }

    .featured-products {
        flex-direction: column; 
        padding: 10px;
    }

    .featured-products div {
        width: 90%; 
        height: auto;
        font-size: 25px;
    }
}
