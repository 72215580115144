.page-products{
  display: flex;
  flex: 1;
}

.products-container{
 flex: 1;
 padding: 20px;
 background-color: var(--bg-amarillo-1);
 font-family: var(--family-body);
}

.show-products{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;  
}


.list-products{
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));  gap: 16px;    
}

.products{
  padding: 20px;
  background-color: var(--bg-blanco);
  border-radius: 20px;
  box-shadow: 1px 1px 1px var(--bg-amarillo-3);
}

.btn-navigate{
padding: 0.75rem;
background-color: var(--bg-rojo);
color: var(--bg-blanco);
border-radius: 1.5rem;
}

.btn-navigate:disabled{
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  .page-products{
    flex-direction: column;
    max-width: 100%;
  }

  .category-container{
    display: none;
  }
  
}