@tailwind base;
@tailwind components;
@tailwind utilities;

/*Variables Globales*/
:root {
    --bg-rojo: #be371f;
    --bg-amarillo-1: #f6f1d3;
    --bg-amarillo-2: #f7cd6b;
    --bg-amarillo-3: #eba128;
    --bg-amarillo-4: #F5E8B1;
    --bg-amarillo-blanco: #f6f1d3;
    --bg-blanco: #ffffffff;
    --bg-negro: #000000;
    --family-body:'Jomolhari';
  }
  