/*HEADER*/
.header-1 {
  background-color: var(--bg-rojo);
  color: var(--bg-blanco);
  text-align: center;
}

.header-2 {
  background-color: var(--bg-amarillo-1);
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.search-container {
  display: flex;
  align-items: center;
  border-radius: 20px;
  background-color: var(--bg-blanco);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.search-input {
  border-radius: 30px;
  width: 325px;
  border: none;
  outline: none;
  background: none;
}

.search-button {
  background-color: var(--bg-rojo);
  padding: 13px;
  border-radius: 30px;
}

.search-button:focus {
  outline: none;
}

.btn-sendMessage:hover {
  background-color: transparent;
}

/*Lista de Sugerencias*/

.suggestions-list {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  border-radius: 20px;
  list-style-type: circle transparent;
  padding: 0;
  margin: 0;
  width: 350px;
  max-height: 150px;
  overflow-y: scroll;
  margin-top: 6.5rem;
  z-index: 1000;
}

.suggestions-list li {
  padding: 5px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

/*Navbar*/
.navbar {
  background-color: var(--bg-amarillo-2);
  padding: 5px;
}

.navbar ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/*FOOTER*/
footer {
  background-color: var(--bg-rojo);
  color: var(--bg-blanco);
  position: static;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

/*Categorias*/

.category-container {
  background-color: var(--bg-amarillo-3);
  width: 300px;
  padding: 20px;
  position: relative;
  font-family: var(--family-body);
}

/*Detalle de Venta*/

.detail-sell-container {
  width: 20rem;
  box-shadow: 1px 1px;
  padding: 1.5rem;
  background-color: var(--bg-amarillo-4);
  font-family: var(--family-body);

}

/*Para dispositivos mas pequeños*/

@media screen and (max-width: 768px) {
  /*Header*/
  .search-container {
    display: none;
  }

  /*Navbar*/
  .navbar ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 20px;
  }

  .navbar ul li {
    width: 48%;
  }
    
  .detail-sell-container {
    min-width: 100%;
    min-height: 50vh;
  }


}
